/**
 * overlay-manager.js
 * Responsible for mutually exclusively initiating all overlays on the site.
 */

// Global overlay "lock"
window.EXOverlay = false;

// Indicates that we are past past the DOM ready events and overlays
// are safe to use manually again.
window.EXOverlayTimeout = false;

// Helper function to avoid binding us so heavily to Facebox
window.EXShowOverlay = function (id) {
    document.popupFacebox(id);
};

/**
 * Helper function for gotta register first overlays - "MembersOnly"
 */
window.EXMembersOnlyOverlay = function () {
    if (typeof BEXTracker.isRegistered() === "boolean") {
        // If logged out...
        if (!window.EXOverlay || window.EXOverlayTimeout) {
            if (document.getElementById("overlayHaveToRegister")) {
                setTimeout(function () {
                    // Defer execution to avoid the overlay loading before a URL hash has had a chance to scroll you down the page
                    window.EXShowOverlay("overlayHaveToRegister");
                    window.EXOverlay = "MembersOnly";
                    if (typeof BEXTestimonialsWidget === "object") {
                        BEXTestimonialsWidget.init({
                            count: 1,
                            divSelector: ".facebox-content .testimonialsOverlay",
                        });
                    }
                }, 0);
            }
        }
    }
};

/**
 * Helper function for Promotional Overlay for Blue Shield of California on registration.
 **/
window.PromotionalCaliforniaOverlay = function () {
    if (!window.EXOverlay || window.EXOverlayTimeout) {
        var overlayID = "overlayPromoCalifornia";
        if (document.getElementById(overlayID)) {
            // Defer execution to avoid the overlay loading before a URL hash has had a chance to
            // scroll you down the page
            window.EXShowOverlay(overlayID);
            // Track Popup Shown
            BEXTracker.trackPromotionalCaliforniaOverlay("event77");
        }
    }
    $(".facebox-content a#btn-continue-registering").on("click", function () {
        // Dismiss the popup when this button is clicked (to take users back to direct registration),
        // and track Popup Reg Continued.
        BEXTracker.trackPromotionalCaliforniaOverlay("event80");
        jQuery(document).trigger("close.facebox");
    });
    $(".facebox-content .cta-btn").on("click", function () {
        // Track Popup CTA clicked.
        BEXTracker.trackPromotionalCaliforniaOverlay("event79");
        jQuery(document).trigger("close.facebox");
    });
    $(".facebox-header a.close").on("click", function () {
        // Track Popup Closed.
        BEXTracker.trackPromotionalCaliforniaOverlay("event78");
    });
};

// Overlays that happen on DOM Ready:
$(function () {
    /**
     * Acquisition welcome overlay - "AcquisitionWelcome"
     */
    (function () {
        if (window.EXOverlay || BEXTracker.isRegistered()) {
            return;
        }
        if (
            document.cookie.match(/SoftAcquisition=/) &&
            !document.cookie.match(/SoftAcquisitionTimeout=/)
        ) {
            var expiry = new Date();
            expiry = new Date(expiry.getTime() + 15 * 60 * 60 * 1000);
            window.EXShowOverlay("acquisition-welcome-overlay");
            document.cookie = "SoftAcquisitionTimeout=1; expires=" + expiry; // Only fire once per 8 hours
            $(".facebox-content a.ex-button").click(function () {
                BEXTracker.trackOverlayLink("Acquisition:Welcome:Registration");
            });
            $(".facebox-header a.close").click(function () {
                BEXTracker.trackOverlayLink("Acquisition:Welcome:Close");
            });
            window.EXOverlay = "AcquisitionWelcome";
        }
    })();

    /**
     * MQP full plan - "FullMQP"
     */
    (function () {
        if (
            (window.location.hash === "#full-plan" || window.FirstMQPVisit) &&
            window.personID &&
            window.location.pathname === "/ex-plan/"
        ) {
            if (!window.EXOverlay) {
                window.ExplodeMQP();
                window.EXOverlay = "FullMQP";
            }
            return;
        }
    })();

    // After about 50ms, free up the overlays for things like the
    // "HaveToRegister" overlay that happens on click
    setTimeout(function () {
        window.EXOverlayTimeout = true;
    }, 50);
});
